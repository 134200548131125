import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { makeFormData, prepareHeaders } from './helpers';
import { cloneDeep, get, omit, omitBy, startsWith } from 'lodash';
import store from './';

const { REACT_APP_API_URL_SERVICING } = process.env;

const setFilterForSenderRecipientWalletName = (filter = {}) => {
  const returnFilter = cloneDeep(filter);
  delete returnFilter.senderName;
  if (filter && filter.sender && !startsWith(get(filter, 'sender'), '0x')) {
    returnFilter.senderName = filter.sender;
    delete returnFilter.sender;
  }

  delete returnFilter.recipientName;
  if (filter && filter.recipient && !startsWith(get(filter, 'recipient'), '0x')) {
    returnFilter.recipientName = filter.recipient;
    delete returnFilter.recipient;
  }
  return returnFilter;
};

const api = createApi({
  reducerPath: 'tokens',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING + '/tokens/',
    prepareHeaders,
  }),
  tagTypes: ['tokenDocuments', 'tokenRequestsCount', 'investorRequestsCount', 'dvaRequestsCount'],
  endpoints: builder => ({
    tokens: builder.query({
      query: () => '',
    }),
    token: builder.query({
      query: id => id,
    }),
    deleteToken: builder.mutation({
      query: id => ({
        url: `${id}`,
        method: 'DELETE',
      }),
    }),
    tokenLogoUpload: builder.mutation({
      query: data => ({
        url: `${data.tokenId}/upload/logo`,
        method: 'POST',
        body: makeFormData(data),
      }),
    }),
    transactions: builder.query({
      query: ({ tokenId, ...params }) => {
        const cleanQuery = omitBy(params, value => !value);
        const filter = setFilterForSenderRecipientWalletName(get(cleanQuery, 'filter', {}));
        return {
          url: `/${tokenId}/transactions`,
          params: {
            ...omit(cleanQuery, 'filter'),
            ...filter,
            includeHolder: true,
          },
        };
      },
    }),
    pauseToken: builder.mutation({
      query: ({ tokenId, tokenAddress }) => ({
        url: `${tokenId}/actions/pause`,
        method: 'POST',
        body: {
          tokenAddress,
        },
      }),
    }),
    unpauseToken: builder.mutation({
      query: ({ tokenId, tokenAddress }) => ({
        url: `${tokenId}/actions/unpause`,
        method: 'POST',
        body: {
          tokenAddress,
        },
      }),
    }),
    validateTokenAction: builder.mutation({
      query: ({ type, tokenId, ...transactions }) => {
        const action = new Map([
          ['mint', 'mint'],
          ['burn', 'burn'],
          ['block', 'freeze'],
          ['unblock', 'unfreeze'],
          ['pause', 'pause'],
          ['unpause', 'unpause'],
          ['forced-transfer', 'forced-transfer'],
        ]);
        return {
          method: 'POST',
          url: `/${tokenId}/actions/validate/${action.get(type)}`,
          body: {
            ...transactions,
          },
        };
      },
    }),
    createTokenAction: builder.mutation({
      query: ({ type, tokenId, ...transactions }) => {
        const action = new Map([
          ['mint', 'mint'],
          ['burn', 'burn'],
          ['block', 'freeze'],
          ['unblock', 'unfreeze'],
        ]);
        return {
          method: 'POST',
          url: `/${tokenId}/actions/${action.get(type)}/batch`,
          body: {
            ...transactions,
          },
        };
      },
    }),
    createTokenForcedTransfer: builder.mutation({
      query: ({ tokenId, tokenQuantity, senderWallet, recipientWallet }) => ({
        method: 'POST',
        url: `/${tokenId}/actions/forced-transfer`,
        body: {
          tokenQuantity: tokenQuantity.toString(),
          senderWallet,
          recipientWallet,
        },
      }),
    }),
    tokenAgents: builder.query({
      query: ({ tokenId }) => `${tokenId}/permissions`,
    }),
    addPermission: builder.mutation({
      query: ({ tokenId, ...body }) => ({
        url: `${tokenId}/permissions`,
        method: 'POST',
        body,
      }),
    }),
    addAgentWallet: builder.mutation({
      query: ({ tokenId, wallet, body }) => ({
        url: `${tokenId}/wallets/${wallet}/permissions/agent-wallet`,
        method: 'POST',
        body,
      }),
    }),
    deleteTokenAgent: builder.mutation({
      query: ({ tokenId, accountId }) => ({
        url: `${tokenId}/permissions/${accountId}`,
        method: 'DELETE',
      }),
    }),
    deleteAgentWallet: builder.mutation({
      query: ({ tokenId, wallet }) => ({
        url: `${tokenId}/wallets/${wallet}/permissions/agent-wallet`,
        method: 'DELETE',
      }),
    }),
    updatePermission: builder.mutation({
      query: ({ tokenId, ...body }) => ({
        url: `${tokenId}/permissions`,
        method: 'PUT',
        body,
      }),
    }),
    tokenOperations: builder.query({
      query: ({ tokenId, query }) => {
        const cleanQuery = omitBy(query, value => !value);
        const filter = get(cleanQuery, 'filter', {});

        return {
          url: `${tokenId}/trex-operations`,
          params: {
            ...omit(cleanQuery, 'filter'),
            ...filter,
          },
        };
      },
    }),
    tokenRequests: builder.query({
      query: ({ tokenId, ...params }) => {
        const cleanQuery = omitBy(params, value => !value);
        const filter = setFilterForSenderRecipientWalletName(get(cleanQuery, 'filter', {}));
        return {
          url: `${tokenId}/requests`,
          params: {
            ...omit(cleanQuery, 'filter'),
            ...filter,
          },
        };
      },
    }),
    tokenRequestsCount: builder.query({
      query: ({ tokenId }) => ({
        url: `${tokenId}/requests`,
        params: {
          offset: 0,
          limit: 1,
          status: 'PENDING_APPROVAL',
          type: 'CONDITIONAL_TRANSFER',
          sortBy: 'date',
          sortDirection: 'DESC',
        },
      }),
      providesTags: (_result, _error, { tokenId }) => [{ type: 'tokenRequestsCount', id: tokenId }],
      transformResponse: response => response.totalItemsFound,
    }),
    investorRequestsCount: builder.query({
      query: ({ tokenId }) => ({
        url: `${tokenId}/requests`,
        params: {
          offset: 0,
          limit: 1,
          status: 'PENDING_APPROVAL',
          type: 'KYC_CHECK',
          sortBy: 'date',
          sortDirection: 'DESC',
        },
      }),
      providesTags: (_result, _error, { tokenId }) => [
        { type: 'investorRequestsCount', id: tokenId },
      ],
      transformResponse: response => response.totalItemsFound,
    }),
    tokenDocuments: builder.query({
      query: ({ tokenId }) => ({
        url: `${tokenId}/documents`,
      }),
      providesTags: (_result, _error, { tokenId }) => [
        {
          type: 'tokenDocuments',
          id: tokenId,
        },
      ],
    }),
    deleteTokenDocument: builder.mutation({
      query: ({ tokenId, url }) => ({
        method: 'PUT',
        url: `${tokenId}/documents/delete`,
        body: { url },
      }),
      invalidatesTags: (_result, _error, { tokenId }) => [
        {
          type: 'tokenDocuments',
          id: tokenId,
        },
      ],
    }),
    addTokenDocument: builder.mutation({
      query: ({ tokenId, document, label, language }) => ({
        method: 'POST',
        url: `${tokenId}/documents`,
        body: makeFormData({
          document,
          label,
          language,
        }),
      }),
      invalidatesTags: (_result, _error, { tokenId }) => [
        {
          type: 'tokenDocuments',
          id: tokenId,
        },
      ],
    }),
    editTokenDocument: builder.mutation({
      query: ({ tokenId, url, label, language }) => ({
        method: 'PUT',
        url: `${tokenId}/documents`,
        body: {
          url,
          label,
          language,
        },
      }),
      invalidatesTags: (_result, _error, { tokenId }) => [
        {
          type: 'tokenDocuments',
          id: tokenId,
        },
      ],
    }),
    acceptTokenRequest: builder.mutation({
      query: ({ tokenId, requestId }) => ({
        method: 'POST',
        url: `${tokenId}/requests/${requestId}/conditional-transfer/approve`,
        body: {
          result: 'APPROVED',
        },
      }),
      invalidatesTags: (_result, _error, { tokenId }) => [
        { type: 'tokenRequestsCount', id: tokenId },
      ],
    }),
    rejectTokenRequest: builder.mutation({
      query: ({ tokenId, requestId, reason }) => ({
        method: 'POST',
        url: `${tokenId}/requests/${requestId}/conditional-transfer/approve`,
        body: {
          result: { result: 'REJECTED', reason },
        },
      }),
      invalidatesTags: (_result, _error, { tokenId }) => [
        { type: 'tokenRequestsCount', id: tokenId },
      ],
    }),
    tokenValuation: builder.query({
      query: ({ tokenId, from, to }) => ({
        url: `${tokenId}/valuation`,
        params: from && to ? { from, to } : {},
      }),
    }),
    addTokenValuation: builder.mutation({
      query: ({ tokenId, valuation, currency, date }) => ({
        method: 'POST',
        url: `${tokenId}/valuation`,
        body: {
          valuation,
          currency,
          date,
        },
      }),
    }),
    tokenInfos: builder.query({
      query: ({ tokenId }) => `${tokenId}/info`,
    }),
    sharedBalances: builder.query({
      query: ({ tokenId, wallet }) => ({
        url: `${tokenId}/balances/shared/${wallet}`,
      }),
    }),
    recoverTokens: builder.mutation({
      query: ({ tokenId, newWallet, oldWallet, holderId }) => ({
        method: 'POST',
        url: `${tokenId}/holder/${holderId}/recover`,
        body: {
          newWallet,
          oldWallet,
        },
      }),
    }),
    dvaTransferRequests: builder.query({
      query: ({ tokenId, ...params }) => {
        const cleanQuery = omitBy({ ...params }, value => !value);
        const filter = get(cleanQuery, 'filter', {});
        return {
          url: `${tokenId}/dva-transfers`,
          params: {
            ...omit(cleanQuery, 'filter'),
            ...filter,
          },
        };
      },
    }),
    dvaRequestsCount: builder.query({
      query: ({ tokenId }) => ({
        url: `${tokenId}/dva-transfers`,
        params: {
          status: 'AGENT_REVIEW',
          offset: 0,
          limit: 1,
        },
      }),
      providesTags: (_result, _error, { tokenId }) => [{ type: 'dvaRequestsCount', id: tokenId }],
      transformResponse: response => response.totalItemsFound,
    }),
    approveDvaTransfer: builder.mutation({
      query: ({ tokenId, transferId, approverWallet }) => ({
        method: 'POST',
        url: `${tokenId}/actions/dva-transfers/${transferId}/approve`,
        body: {
          approverWallet,
        },
      }),
    }),
    sendApprovedDvaTransferHash: builder.mutation({
      query: ({ tokenId, transferId, signerWallet, txHash }) => ({
        method: 'POST',
        url: `${tokenId}/actions/dva-transfers/${transferId}/approve/transactions`,
        body: {
          txHash,
          signerWallet,
        },
        invalidatesTags: (_result, _error, { tokenId }) => [
          { type: 'dvaRequestsCount', id: tokenId },
        ],
      }),
    }),
    rejectDvaTransfer: builder.mutation({
      query: ({ tokenId, transferId }) => ({
        method: 'POST',
        url: `${tokenId}/actions/dva-transfers/${transferId}/reject`,
      }),
    }),
    sendRejectedDvaTransferHash: builder.mutation({
      query: ({ tokenId, transferId, signerWallet, txHash }) => ({
        method: 'POST',
        url: `${tokenId}/actions/dva-transfers/${transferId}/reject/transactions`,
        body: {
          txHash,
          signerWallet,
        },
        invalidatesTags: (_result, _error, { tokenId }) => [
          { type: 'dvaRequestsCount', id: tokenId },
        ],
      }),
    }),
  }),
});

export default api;

export const {
  useTokensQuery,
  useTokenQuery,
  useLazyTokenQuery,
  useDeleteTokenMutation,
  useTokenLogoUploadMutation,
  usePauseTokenMutation,
  useUnpauseTokenMutation,
  useCreateTokenActionMutation,
  useCreateTokenForcedTransferMutation,
  useValidateTokenActionMutation,
  useTokenAgentsQuery,
  useAddPermissionMutation,
  useAddAgentWalletMutation,
  useDeleteTokenAgentMutation,
  useDeleteAgentWalletMutation,
  useUpdatePermissionMutation,
  useLazyTokenOperationsQuery,
  useLazyTokenAgentsQuery,
  useLazyTransactionsQuery,
  useTokenRequestsQuery,
  useLazyTokenRequestsQuery,
  useTokenDocumentsQuery,
  useDeleteTokenDocumentMutation,
  useAddTokenDocumentMutation,
  useEditTokenDocumentMutation,
  useTokenRequestsCountQuery,
  useInvestorRequestsCountQuery,
  useAcceptTokenRequestMutation,
  useRejectTokenRequestMutation,
  useLazyTokenValuationQuery,
  useAddTokenValuationMutation,
  useLazyTokenInfosQuery,
  useLazySharedBalancesQuery,
  useRecoverTokensMutation,
  useLazyPendingDvaTransferRequestsQuery,
  useLazyDvaTransferRequestsQuery,
  useDvaRequestsCountQuery,
  useApproveDvaTransferMutation,
  useSendApprovedDvaTransferHashMutation,
  useRejectDvaTransferMutation,
  useSendRejectedDvaTransferHashMutation,
} = api;

export const getTransactionsCSVURL = async ({ tokenId, ...query }) => {
  const cleanQuery = omitBy(query, value => !value);
  const filter = setFilterForSenderRecipientWalletName(get(cleanQuery, 'filter'));

  const queryString = new URLSearchParams({
    ...omit(cleanQuery, ['filter', 'offset', 'limit']),
    ...filter,
    includeHolder: true,
    jwt: store.getState().aws.jwt,
  });

  const url = new URL(`${REACT_APP_API_URL_SERVICING}/tokens/${tokenId}/transactions/csv`);
  url.search = queryString;

  return url.toString();
};
