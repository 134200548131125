import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore, PERSIST, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import selected from './selected';
import tokens from './tokens';
import tokenHolders from './tokenHolders';
import marketplaces from './marketplaces';
import billboard from './billboard';
import issuerPortal from './issuerPortal';
import holders from './holders';
import candidates from './candidates';
import positionReports from './positionReports';
import accounts from './accounts';
import cex from './cex';
import multichain from './multichain';
import redemptions from './redemptions';
import auth from './auth';
import issuers from './issuers';
import subscriptions from './subscriptions';
import trexFactory from './trexFactory';

import tokenTrader from './tokenTrader';
import aws from './aws';
import { setupListeners } from '@reduxjs/toolkit/query';

const stores = [
  tokenTrader,
  tokens,
  tokenHolders,
  marketplaces,
  billboard,
  holders,
  issuerPortal,
  candidates,
  positionReports,
  accounts,
  cex,
  multichain,
  redemptions,
  auth,
  issuers,
  subscriptions,
  trexFactory,
];

const persistedStores = [aws, selected];

const store = configureStore({
  reducer: {
    ...stores.reduce(
      (acc, store) => ({
        ...acc,
        [store.reducerPath || store.name]: store.reducer,
      }),
      {},
    ),
    ...persistedStores.reduce(
      (acc, store) => ({
        ...acc,
        [store.reducerPath || store.name]: persistReducer(
          {
            key: store.reducerPath || store.name,
            storage,
          },
          store.reducer,
        ),
      }),
      {},
    ),
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST, REGISTER],
      },
    }).concat(
      stores
        .concat(persistedStores)
        .filter(store => store.middleware)
        .map(store => store.middleware),
    ),
});

setupListeners(store.dispatch);

export default store;
export const persistor = persistStore(store);

export const resetStores = () => {
  stores.map(api => {
    return store.dispatch(api.util.resetApiState());
  });
  persistedStores.map(slice => {
    return store.dispatch(slice.actions.reset());
  });
};
