import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { makeFormData, prepareHeaders } from './helpers';
import issuerPortal from './issuerPortal';

const { REACT_APP_API_URL_SERVICING, REACT_APP_ISSUER_PORTAL_ID } = process.env;

const api = createApi({
  reducerPath: 'issuers',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL_SERVICING + '/issuers/',
    prepareHeaders,
  }),
  tagTypes: ['issuers'],
  endpoints: builder => ({
    issuers: builder.query({
      query: () => ({
        url: `/`,
        params: { issuerPortalId: REACT_APP_ISSUER_PORTAL_ID },
      }),
      providesTags: () => [{ type: 'issuers', id: 'all' }],
    }),
    createIssuer: builder.mutation({
      query: body => ({
        method: 'POST',
        url: `/`,
        body: {
          ...body,
          issuerPortalId: REACT_APP_ISSUER_PORTAL_ID,
        },
      }),
      invalidatesTags: () => [{ type: 'issuers', id: 'all' }],
    }),
    createToken: builder.mutation({
      query: ({ issuerId, ...request }) => ({
        method: 'POST',
        url: `/${issuerId}/tokens`,
        body: makeFormData(request),
      }),
      async onQueryStarted(_req, { dispatch, queryFulfilled }) {
        try {
          // pessimistic cache update, wait for query to run
          const { data } = await queryFulfilled;
          // forcefully add data to issuerPortal tokens query
          dispatch(
            issuerPortal.util.updateQueryData('tokens', undefined, draft => {
              draft.push({
                ...data,
                // api doesn't return these, but we need them to render
                status: 'NOT_DEPLOYED',
                isPaused: false,
                permissions: ['OWNER'],
                image: data.logo,
              });
            }),
          );
          // queue refetch of portal tokens
          dispatch(issuerPortal.util.invalidateTags([{ type: 'tokens', id: 'all' }]));
        } catch {}
      },
    }),
  }),
});

export default api;

export const { useIssuersQuery, useCreateIssuerMutation, useCreateTokenMutation } = api;
